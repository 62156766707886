fetch('https://ipgeolocation.abstractapi.com/v1/?api_key=0ea380a8dd71490cb802d829d93b242d')
  .then(result => result.json())
  .then((output) => {
    forAbroad(output.country);
  }).catch(err => console.error(err));

function forAbroad(country) {
  const url = window.location.href.split('/');
  const caseStudy = url[url.length - 1];
  const elements = document.querySelectorAll('.for-abroad');
  const plElements = document.querySelectorAll('.for-poland')
  if (country === "Poland") {
    if (caseStudy.includes("vw-nora") || caseStudy.includes("optiparts") || caseStudy.includes("vw-ecommerce")) {
      window.location.href = '/';
    }
    elements.forEach(element => {
      element.classList.add("for-abroad-hidden");
      element.classList.remove("for-abroad-visible");
    });
    plElements.forEach(element => {
      element.classList.add("for-abroad-visible");
      element.classList.remove("for-abroad-hidden");
    });
  } else {
    elements.forEach(element => {
      element.classList.add("for-abroad-visible");
      element.classList.remove("for-abroad-hidden");
    });
    plElements.forEach(element => {
      element.classList.add("for-abroad-hidden");
      element.classList.remove("for-abroad-visible");
    });
  }
}
